import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
import * as assetsApi from '@api/assets';
import Vue from 'vue';
import { mapGetters } from 'vuex';
export default {
  name: 'AssetsDispatch',
  mixins: [common],
  computed: {
    ...mapGetters('user', ['projectInfo'])
  },
  data() {
    return {
      iconRoles: {},
      menuListData: [{
        id: '1',
        name: '调拨申请',
        svgName: 'assets_dispatch_apply',
        backgroundColor: 'transparent',
        routeName: 'AssetsDispatchApply',
        isShow: true,
        notifyNum: 0
      }, {
        id: '2',
        name: '我发起的\r\n申请记录',
        svgName: 'assets_dispatch_apply_log_commit',
        backgroundColor: 'transparent',
        routeName: 'AssetsDispatchApplyLogCommit',
        isShow: true,
        notifyNum: 0
      }, {
        id: '3',
        name: '调拨出库',
        svgName: 'assets_dispatch_out',
        backgroundColor: 'transparent',
        routeName: 'AssetsDispatchOut',
        isShow: true,
        auth: 'transferIssue',
        notifyNum: 0
      }, {
        id: '4',
        name: '出库记录',
        svgName: 'assets_dispatch_out_log',
        backgroundColor: 'transparent',
        routeName: 'AssetsDispatchOutLog',
        isShow: true,
        auth: 'transferIssue',
        notifyNum: 0
      }, {
        id: '5',
        name: '向我发起的\r\n申请记录',
        svgName: 'assets_dispatch_apply_log_receive',
        backgroundColor: 'transparent',
        routeName: 'AssetsDispatchApplyLogReceive',
        isShow: true,
        auth: 'toBeApprovedByMe',
        notifyNum: 0
      }]
    };
  },
  async created() {
    let res = await assetsApi.getIconList();
    if (res.data.data && res.data.data.iconList) {
      for (let role of res.data.data.iconList) {
        Vue.set(this.iconRoles, role, true);
      }
    }
    let {
      dcProjectId
    } = this.projectInfo;
    let res2 = await assetsApi.getOtherProjectCount({
      projectId: dcProjectId
    });
    let count = res2.data.data.result.count;
    this.menuListData[4].notifyNum = count;
  },
  methods: {
    goModule(data = {}) {
      let {
        routeName
      } = data;
      this.$router.push({
        name: routeName
      });
    }
  }
};