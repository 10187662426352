var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper",
    staticStyle: {
      "background-color": "#fff"
    }
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.routeMetaTitle
          },
          on: {
            "go-back": _vm.goBack
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "cyui-menu-box"
        }, [_c('div', {
          staticClass: "cyui-flexbox cyui-flexbox-wrap cyui-menu"
        }, [_vm._l(_vm.menuListData, function (item, index) {
          return [item.isShow && (!item.auth || _vm.iconRoles[item.auth]) ? _c('div', {
            key: index,
            staticClass: "cyui-menu-item",
            on: {
              "click": function ($event) {
                return _vm.goModule(item);
              }
            }
          }, [_c('div', {
            staticClass: "cyui-menu-icon",
            style: {
              backgroundColor: item.backgroundColor,
              width: "48px",
              height: "48px"
            }
          }, [item.size ? _c('svg-icon', {
            style: {
              width: item.size,
              height: item.size
            },
            attrs: {
              "icon-class": item.svgName
            }
          }) : _c('svg-icon', {
            style: {
              width: "48px",
              height: "48px"
            },
            attrs: {
              "icon-class": item.svgName
            }
          }), item.notifyNum ? _c('van-badge', {
            attrs: {
              "content": item.notifyNum,
              "max": "99"
            }
          }) : _vm._e()], 1), _c('div', {
            staticClass: "cyui-menu-label"
          }, [_vm._v(_vm._s(item.name))])]) : _vm._e()];
        })], 2)])];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };